import "styles/pages/post.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const Post = ({ pageContext }) => {
  const { data } = pageContext
  const title = data.title

  return (
    <Layout>
      <Seo
        title={data.seo.title ? data.seo.title : title}
        description={data.seo.metaDesc && data.seo.metaDesc}
        ogImage={
          data?.seo?.opengraphImage?.sourceUrl
            ? data?.seo.opengraphImage?.sourceUrl
            : data?.featuredImage?.node?.sourceUrl
        }
      />
      <PageHeader title={title} />
      <Breadcrumbs title={title} parent="Tidindi" />

      <section className="post">
        <div className="container-fluid">
          <div className="post__image">
            {data?.featuredImage && (
              <img
                className="img-fluid"
                src={data?.featuredImage?.node?.sourceUrl}
                alt=""
              />
            )}
          </div>
          <time>{data.date.split("T")[0].split("-").reverse().join(".")}</time>
          <h2>{title}</h2>
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Post
