import "./page-header.scss"
import React from "react"

const PageHeader = ({ title }) => {
  return (
    <section className="page-header">
      <div className="page-header__wrapper">
        <div className="container-fluid">{title}</div>
      </div>
    </section>
  )
}

export default PageHeader
