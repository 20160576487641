import "./breadcrumbs.scss"
import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ title, parent, color }) => {
  return (
    <section className="breadcrumbs">
      <div className="container-fluid">
        <Link to="/" style={{ color: color }}>
          Heim
        </Link>
        {parent && (
          <Link to={`/${parent.toLowerCase().replace("ø", "o")}/`}>
            {parent}
          </Link>
        )}
        <span style={{ color: color }}>{title}</span>
      </div>
    </section>
  )
}

export default Breadcrumbs
